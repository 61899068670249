export const environment = {
	name:'dev',
	production: false,
	imageUrl: "https://stbti-admin.stoltdev.com",
	baseUrl: "/api/v1?page=",
	hostURL: "https://stbti.stoltdev.com",
	strapiToken:"0bb2e3dbd9f41b81c479babb2c35a945ff79e59b8c1f600bc020430d97fafdc6e9c04583e0aa1a0850e8f9a79a23e4c0d5c4928324324924ccbb4e5cdec9871a93588df4c56cc1ebab590a9ca7e28d8935b2e6d2af8e6cf60e7c16ea916ba5eca1ed6dbac255525548c9395da2bb4ded0e28a2f630012fe8fcf78505d08563c4",
	port: 4201,
	googleAnalytic:'dev',
    googleAnalyticKey:'G-SG7VT0LZJP',
    googleTagManager:'GTM-PJT8DB76',
	clientId: "9c168ea0-29d5-4225-9909-0dd9be37470d",
	authority: "https://login.microsoftonline.com/74437d1e-19e1-4555-bfcb-c6d8bace6725",
	redirectUri: "https://stbti.stoltdev.com",
	GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me'
};