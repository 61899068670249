<div class="top-section" *ngIf="topSection">
    <img class="w-100" *ngIf="topSection?.image" [src]="topSection.image" alt="Page Banner Image">
    <div class="card-img-overlay d-flex justify-content-center align-items-end overlay-dark"></div>
    <div class="container2">
        <div class="allocate-finance">
            <h1 *ngIf="topSection?.Title">{{topSection.Title}}</h1>
            <div class="buttons">
                <div *ngIf="topSection?.Links?.URL && topSection?.Links?.Label">
                    <snl-button
                        [type]="'primary'"
                        [size]="'large'"
                        [icon]="'fa-solid fa-arrow-up-right-from-square'"
                        [disabled]="false"
                        (buttonClick)="launchClick(topSection.Links.URL)"
                        >
                        {{topSection.Links.Label}}
                    </snl-button>
                </div>
                <div class="subUnsubBtn">
                    <snl-button
                        [class]="subUnsubClass"
                        [size]="'large'"
                        [disabled]="false"
                        (buttonClick)="subUnsub()"
                        >
                        {{subUnsubText}}
                    </snl-button>
                </div>
            </div>
        </div>
    </div>
</div>